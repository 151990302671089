import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title="Blog" />
      <div>
        <h2>{frontmatter.title}</h2>
        <div className="italic">{frontmatter.date}</div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <p className="mt-12 mb-6">
        <Link to="/">Go back to the homepage</Link>
      </p>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        path
        title
      }
    }
  }
`
